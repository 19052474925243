.targét-item-container {
  display: flex;
  height: 75vh;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.targét-item-picture {
  border-radius: .4rem;

}
.targét-item-header {
  margin: 1vh;
  text-align: left;

}

.top-half {
  width: 80vw;
}

.targét-item-info-container {
  display: flex;
  margin-left: 1vh;
  flex-direction: column;
  border: lightgray solid 1px;
  border-radius: .4rem;
  width: 80vw;
}

.targét-item-info-container > h2, .targét-item-info-container > div {
  margin: 1vh;
}

.review-card-container {
  display: flex;
  border: lightgray solid 1px;
  border-radius: .4rem;
  flex-direction: column;
  width: 80vw;
}

.review-card-container-copy {
  display: flex;
  border: lightgray solid 1px;
  border-radius: .4rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80vw;
  margin: 1vh;
  padding: 1vh 0;
}

.bottom-half > button {
  width: fit-content;
  height: 1.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 1vh;
  border-radius: .4rem;
  background-color: black;
  color: white;
  font-weight: bolder;
  border: none;
}

.bottom-half > button:hover {
  background-color: rgb(223, 36, 36);
  border: none;
}
.review-content-container {
  display: flex;
  flex-direction: column;
  margin: 1vh 2vh;
}

.review-image {
  width: 70px;
  height: 70px;
  border-radius: .4rem;
}

.bottom-half {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.review-h4 {
  margin-top: 0;
  margin-bottom: .4rem;
}

.tiny-review-name {
  font-size: .6rem;
  color: gray;
  margin-bottom: .4rem;
}

.review-card-container {
  margin: 1vh;
}

.middle-part {
  display: flex;
  width: 80vw;
  margin-top: 1vh;
  border: lightgray solid 1px;
  border-radius: .4rem;
}

.middle-part > p {
  margin: 2vh;
}

.targét-item-content-container {
  display: flex;
  width: 80vw;
}

.add-to-cart-button {
  display: flex;
  margin: 1vh;
}

.div-gap {
  display: flex;
  gap: .5vw;
  align-items: center;
}

.div-gap > button {
  background-color: black;
  color: white;
  border: none;
  border-radius: .4rem;
  padding: 1vh;
  font-weight: bold;
}

.div-gap > button:hover {
  background-color: rgb(223, 36, 36);
}

.side-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.space-between {
  display: flex;
  gap: 1vh;
  margin-right: 2vh;
  flex-direction: column;
  align-items: flex-end;
}

.space-between > button {
  width: 98px;
  height: 1.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  border-radius: .4rem;
  background-color: black;
  color: white;
  font-weight: bolder;
  border: none;
}

.space-between > button:hover {
  background-color: rgb(223, 36, 36);
}

.add-to-wishlist-button {
  width: fit-content;
  height: 1.8rem;
  display: flex;
  justify-content: center;
  margin-left: 1vh;
  align-items: center;
  border-radius: .4rem;
  background-color: black;
  color: white;
  font-weight: bolder;
  border: none;
}

.ip-next-item {
  display: flex;
  width: 50px;
  position: fixed;
  right: 3vw;
  top: 35vh;
  cursor: pointer;
}

.ip-previous-item {
  display: flex;
  width: 50px;
  position: fixed;
  left: 3vw;
  top: 35vh;
  cursor: pointer;
}

.ip-next-item:hover {
  content: url('../../assets/arr-right-red.png')
}

.ip-previous-item:hover {
  content: url('../../assets/arr-left-red.png');
}
