.review-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3vh;
  gap: 1vh
}

.review-header {
  display: flex;
  justify-content: center;
}

.review-div {
  display: flex;
  flex-direction: column;
  margin: 1vh 0;
}

.review-button {
  margin: 1vh 0;
  background-color: black;
  color: white;
  border: none;
  border-radius: .4rem;
  width: 100%;
  padding: 1vh;
  font-weight: bold;
}

.review-button:hover {
  background-color: rgb(223, 36, 36);
}

.review-input {
  border-radius: .4rem;
  height: 1.3rem;
  width: 220px;
}
