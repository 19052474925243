.wishlist-page-body {
  display: flex;
  height: 17vh;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, .6);
}

.wishlist-header > button {
  background-color: transparent;
  border: none;
  font-size: 24px;
  font-weight: bold;
}

.wishlist-header > button:hover {
  color: rgb(223, 36, 36);
}

.wishlist-icon {
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1vh auto;
}

.wishlist-icon-container {
  display: flex;
  align-self: center;
  flex-direction: column;
  width: fit-content;
  height: 100%;
  margin-left: 1vh;
  margin-right: 3vh;
  justify-content: space-evenly;
}

.wishlist-icon-container > button {
  background-color: black;
  color: white;
  border: none;
  border-radius: .4rem;
  font-size: .5rem;
  width: fit-content;
  font-weight: bold;
  width: 10vh;
  padding: .5vh;
}

.wishlist-icon-container > button:hover {
  background-color: rgb(223, 36, 36);
}

.wishlist-container {
  display: flex;
  width: 80vw;
  overflow-x: auto;
  margin: 1vh 1vh 1vh 0;
  overflow-y: hidden;
}

.each-wishlist {
  display: flex;
  flex-direction: column;
  height: 10vh;
  width: 10vh;
  margin: 0 0 1vh 1vh;
}

.wishlist-image {
  height: 10vh;
  width: 10vh;
  border-radius: .4rem  ;
}

.wishlist-to-cart {
  background-color: black;
  color: white;
  border: none;
  border-radius: .4rem;
  width: fit-content;
  font-weight: bold;
  font-size: .5rem;
  width: 10vh;
}

.move-to-cart-button {
  background-color: black;
  color: white;
  border: none;
  border-radius: .4rem;
  font-size: .5rem;
  width: fit-content;
  font-weight: bold;
  width: 10vh;

  padding: .5vh;
}

.move-to-cart-button:hover {
  background-color: rgb(223, 36, 36);
}

.wishlist-to-cart:hover {
  background-color: rgb(223, 36, 36);
}

.wishlist-remove {
  display: flex;
  color: white;
  font-weight: bold;
  position: relative;
  left: -15px;
  top: -10.5vh;
  justify-content: center;
  align-items: center;
  width: 10px;
  cursor: pointer;
  background-color: black;
  border: none;
  padding: 0 8px;
  border-radius: .4rem;
}

.wishlist-remove:hover {
  background-color: rgb(223, 36, 36);
}

.wishlist-remove-image {
  width: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wishlist-header-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: auto;
}

.wishlist-buttons {
  display: flex;
}

.wishlist-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wishlist-total {
  color: white;
  background-color: rgb(223, 36, 36);
  font-size: 1rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  width: fit-content;
  left: 29px;
  bottom: 11.2vh;
  padding: .2vh;
  border-radius: 50%;
}


.dev-links-wl {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#github {
  background-color: white;
  border-radius: 50%;
}

#linkedin {
  background-color: white;
  border-radius: .2rem;
}

#github:hover {
  content: url('../../assets/github-red.png')
}

#linkedin:hover {
  content: url('../../assets/linkedin-red.png')
}

.dev-links-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
