.navbar-container {
  display: flex;
  position: sticky;
  height: 8vh;
  justify-content: space-between;
  align-items: center;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, .6);
  padding: 0 3vw;
}

#home:hover {
  content: url('../../assets/home-logo-red.png')
}

.dropdown-container {
  display: flex;
  position: relative;
}
#right-side-container {
  display: flex;
  align-items: center;
}
#right-side-container > button {
  margin-right: 2vw;
}

.dropdown-menu {
  position: absolute;
  right: 5vw;
  display: none;
  width: 10vw;
}


.dropdown-container.open .dropdown-menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 14vw;
  top: 8vh;
  right: 0vw;
  background-color: black;
  align-items: flex-end;
  color: white;
  padding: 2vh;
  border-radius: .4rem;
  gap: 1vh;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, .6);
}

#open {
  display: none;
}

.cart-icon-size {
  width: 45px;
}

.cart-icon-size:hover {
  content: url('../../assets/cart-red.png');

}

.cart-icon-container {
  text-decoration: none;
}

.cart-total {
  color: white;
  background-color: rgb(223, 36, 36);
  font-size: 1rem;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  width: fit-content;
  right: 10px;
  padding: .2vh;
  border-radius: 50%;
}

#right-side-container > button {
  background-color: black;
  color: white;
  border: none;
  border-radius: .4rem;
  width: fit-content;
  padding: 1vh;
  font-weight: bold;
}

#right-side-container > button:hover {
  background-color: rgb(223, 36, 36);
}

.open > button {
  background-color: black;
  color: white;
  border: none;
  border-radius: .4rem;
  width: fit-content;
  padding: 1vh;
  font-weight: bold;
}

.menu-buttons > button {
  background-color: black;
  color: white;
  border: none;
  border-radius: .4rem;
  width: fit-content;
  padding: 1vh;
  font-weight: bold;
}
.tech-drop-button {
  background-color: black;
  color: white;
  border: none;
  border-radius: .4rem;
  width: fit-content;
  padding: 1vh;
  font-weight: bold;
}

.tech-drop-button:hover {
  background-color: rgb(223, 36, 36);
}

.menu-buttons > button:hover {
  background-color: rgb(223, 36, 36);
}

.link-size {
  width: 25px;
}

.dev-links {
  display: flex;
  flex-direction: column;
}

#github {
  background-color: white;
  border-radius: 50%;
}

#linkedin {
  background-color: white;
  border-radius: .2rem;
}

#github:hover {
  content: url('../../assets/github-red.png')
}

#linkedin:hover {
  content: url('../../assets/linkedin-red.png')
}

.nav-search {
  position: absolute;
  left: 35vw;
}

.search-input {
  display: flex;
  border-radius: .4rem;
  width: 29.5vw;
  height: 2vh;
  font-weight: bold;
}

.search-results {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 20vh;
  width: 30vw;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, .6);
  gap: .4vh;
  border-radius: .4rem;
}

.search-results-faux {
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  align-items: center;
  height: 2.7vh;
  width: 30vw;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, .6);
  gap: .4vh;
  border-radius: .4rem;
  color: white;
  font-weight: bold;
  font-size: 14px;
}
.result-container {
  margin: .4vh;
  display: flex;
  text-decoration: none;
  font-weight: bold;
  color: white;
}
.search-image-container {
  height: 10vh;
  width: 10vh;
}

.search-image {
  height: 10vh;
  width: 10vh;
  border-radius: .4rem;
}

.search-result-details {
  margin: .4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
