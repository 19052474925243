/* TODO Add site wide styles */
body {
  margin: 0;
}

* {
  font-family: Helvetica, Arial, sans-serif;
}

button {
  cursor: pointer;
}

