.cart-page-body {
  display: flex;
  height: 75vh;
  overflow-y: auto;
  justify-content: center;
}

.cart-item-card {
  display: flex;
  margin-left: 1vh;
  border: lightgray solid 1px;
  border-radius: .4rem;
  width: 50vw;
  margin: 1vh;
  justify-content: space-between;
  align-items: center;
  padding: .4vh;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.6)
}

.cart-image-container {
  height: 80px;
  width: 80px;
}

.cart-image {
  height: 80px;
  width: 80px;
  border-radius: .4rem;
}

.cart-page-column > h1 {
  margin: 1vh 1vh 0 1vh;
}

.totals-section {
  position: sticky;
  top: 1vh;
  display: flex;
  width: 30vw;
  flex-direction: column;
}

.totals-section > h2 {
  margin: 1vh;
}

.cart-navlink {
  text-decoration: none;
  color: black;
  display: flex;
  align-items: center;
}

.price {
  font-weight: bold;
}

.totals-spacing {
  margin: 1vh;
  display: flex;
  justify-content: space-between;
}

#total {
  font-weight: bold;
  border-top: darkgray solid 1px;
  padding-top: 2vh;
}

.cart-item-details {
  margin: 1vh;
}

.update-cart-align {
  display: flex;
  justify-content: right;
}

.update-field {
  display: flex;
  flex-direction: column;
  justify-self: right;
  align-items: flex-end;
  margin: 0 0 .4vh 0;
  width: 60px;
}

#cart-page-align {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-overflow: ellipsis;
}

.placeholder-button > button {
  background-color: black;
  color: white;
  border: none;
  border-radius: .4rem;
  width: fit-content;
  padding: 1vh;
  font-weight: bold;
}

.placeholder-button > button:hover {
  background-color: rgb(223, 36,36);
}

.cart-item-cards {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-items: flex-end;
  width: 120px;
  gap: .4vh;
}

.remove-button, .update-button {
  width: 20px;
  cursor: pointer;
}

.remove-button:hover {
  content: url('../../assets/cross-red.png');
}

.update-button:hover {
  content: url('../../assets/add-red.png')
}

.button-container {
  display: flex;
  justify-content: space-around;
}

.button-container > button {
  background-color: black;
  color: white;
  border: none;
  border-radius: .4rem;
  padding: 1vh;
  font-weight: bold;
}

.button-container > button:hover {
  background-color: rgb(223, 36, 36);
}

.checkout-button:hover {
  background-color: rgb(223, 36, 36);
}

.cart-buttons {
  background-color: black;
  color: white;
  border: none;
  border-radius: .4rem;
  padding: 1vh;
  font-weight: bold;
}

.cart-buttons:hover {
  background-color: rgb(223, 36,36);
}

#shopping-item-card {
  display: flex;
  border-radius: .4rem;
  border: 1px solid darkgrey;
  padding: 1vh;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.6);
  flex-direction: column;
}

.no-text-decor {
  color: black;
  font-weight: bold;
  display: flex;
  justify-content: center;
}

.shopping-item-container {
  text-decoration: none;
}

.previous-item, .next-item {
  width: 25px;
  cursor: pointer;
}

.last-grab-buttons {
  display: flex;
  justify-content: center;
}

.last-grab-buttons > button {
  background-color: black;
  color: white;
  border: none;
  border-radius: .4rem;
  padding: 1vh;
  font-weight: bold;
}

.cart-item-name {
  width: fit-content;
}

.last-grab-buttons > button:hover {
  background-color: rgb(223, 36,36);
}

.previous-item:hover {
  content: url('../../assets/arr-left-red.png')
}

.next-item:hover {
  content: url('../../assets/arr-right-red.png')
}

.ad-background {
  position: sticky;
  top: 30vh;
  display: flex;
  width: 30vw;
  height: 30vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: bgcolor 20s infinite;
  animation-direction: alternate;
  text-decoration: none;
  border-radius: .4rem;
}

.ad-link {
  text-decoration: none;
}

@keyframes bgcolor {
  0% {
      background-color: #45a3e5
  }

  30% {
      background-color: #66bf39
  }

  60% {
      background-color: #eb670f
  }

  90% {
      background-color: #f35
  }

  100% {
      background-color: #864cbf
  }
}

.ad-button {
  background-color: white;
  border-radius: 1rem;
  width: fit-content;
  padding: 10px;
  text-decoration: none;
  animation: fscolor 20s infinite;
  animation-direction: alternate;
  margin-bottom: 10px;
  border: none;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

@keyframes fscolor {
  0% {
      color: #d2a5ff
  }

  30% {
      color: #73c5ff
  }

  60% {
      color: rgb(255, 159, 175)
  }

  90% {
      color: rgb(255, 202, 102)
  }

  100% {
      color: rgb(234, 255, 159)
  }
}

.ad-label {
  display: flex;
  position: absolute;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: black;
  left: 0.4vw;
  top: 0.1vw;
}
