.landing-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75vh;
  border: 1px darkgray solid;
  width: 80vw;
  border-radius: .4rem;
  margin: 1vh auto;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, .6);
}

.no-text-deco {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

.no-text-deco:hover {
  color: rgb(223, 36, 36);
}
