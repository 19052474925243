.delete-user-review-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3vh;
  gap: 1vh
}

.delete-buttons-container {
  display: flex;
  justify-content: space-evenly;
}

.delete-review-button {
  width: fit-content;
  height: 1.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 1vh;
  border-radius: .4rem;
  background-color: black;
  color: white;
  font-weight: bolder;
  border: none;
}

.delete-review-button:hover {
  background-color: rgb(223, 36, 36);
}
